import React, { useContext, useEffect } from "react";
import {
  IonToast, isPlatform,
} from "@ionic/react";

import AuthContext from "../../context/auth-context";

const CookieModal: React.FC<{
}> = () => {

  const authCtx = useContext(AuthContext);

  
  return (
    <IonToast
    isOpen={!authCtx.cookieAgreement}
    message={"Nous utilisons des cookies pour nous permettre de mieux comprendre comment le site est utilisé. En continuant à utiliser ce site, vous acceptez cette politique."}
    onDidDismiss={(e) => authCtx.acceptCookieAgreement()}
    keyboardClose={true}
    mode='ios'
    //duration={10000}
    position={isPlatform('mobile')?'top':'bottom'}
    buttons={[
      {
        text: "ok",
        role: "cancel",
        side: "end",
        handler: () => {
          authCtx.acceptCookieAgreement();
        },
      },
    ]}
  />
  );
};

export default CookieModal;
