import React, { useState, useEffect } from "react";

import PriceContext from "./price-context";
import { useHttpClient } from "../hooks/http-hook";

const PriceContextProvider: React.FC = (props) => {
  const [priceList, setPriceList] = useState<Array<any>|null>(null);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const populatePriceList = (price_list:Array<object>)=>{
    setPriceList(price_list);
  }

  const fetchPricesSecond = async () => {
    try {
      //replace hardcoded eur by context variable
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "api/shop/prices_" + "eur"
      );
      return responseData.prices;
    } catch (err) {}
  };

  const getPricePerDim = (dim: Number) => {
    //assume list is ordered from smaller dim_cm to higher
    if(priceList === null || priceList.length < 1){
      fetchPricesSecond();
      if(priceList === null || priceList.length < 1){
        return {};
      }else{
        for(var j=0; j<priceList.length;j++){
          if(dim<= priceList[j].dim_cm){
            return priceList[j];
          }
        }
        return {base_price:-1};
      }
    }else{
      for(var j=0; j<priceList.length;j++){
        if(dim<= priceList[j].dim_cm){
          return priceList[j];
        }
      }
      return {base_price:-1};
    }
  };

  useEffect(() => {
    const fetchPrices = async () => {
      try {
        //replace hardcoded eur by context variable
        const responseData = await sendRequest(
          process.env.REACT_APP_BACKEND_URL + "api/shop/prices_" + "eur"
        );
        console.log(responseData);
        populatePriceList(responseData.prices);
      } catch (err) {}
    };
    fetchPrices();
  }, [sendRequest]);

  return (
    <PriceContext.Provider
      value={{
        priceList,
        populatePriceList,
        getPricePerDim
      }}
    >
      {props.children}
    </PriceContext.Provider>
  );
};

export default PriceContextProvider;
