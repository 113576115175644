import React, { useContext } from "react";
import {
  IonToolbar,
  IonButtons,
  IonButton,
  IonIcon,
  IonText,
  IonTitle,
  IonBackButton,
  IonBadge,
} from "@ionic/react";
import { menuController } from "@ionic/core";
import {
  basketOutline,
  personOutline,
  menuOutline,
  arrowBackOutline,
  homeOutline,
} from "ionicons/icons";
import AuthContext from "../../context/auth-context";
import cContext from "../../context/cart-context";

import "../../theme/custom-shop.css";

const CustomShopToolbar: React.FC<{ shop_name: string }> = (props) => {
  const authCtx = useContext(AuthContext);
  const cartCtx = useContext(cContext);
  return (
    <IonToolbar
      style={{
        paddingTop: "8px",
        paddingBottom: "8px",
        backgroundColor: "white",
      }}
    >
      <IonButtons slot="start">
      <IonButton
            routerLink={"/"}
            routerDirection="forward"
            mode="ios"
            color="dark"
            fill="clear"
          >
            <IonIcon icon={homeOutline} />
          </IonButton>
      </IonButtons>
      <IonTitle style={{ position: "absolute" }} className="ion-text-center">
        <IonButton
          routerLink={"/"}
          routerDirection="back"
          mode="ios"
          fill="clear"
          style={{width:'-webkit-fill-available'}}
        >
          <img
            style={{ maxHeight: "54px" }}
            src={"assets/img/Parisian Cars/artist.svg"}
          />
          <p className='nav-featuring-text' style={{ margin:'0px'}}>&nbsp;&nbsp;x&nbsp;&nbsp;</p>
          <img style={{ maxHeight: "30px" }} src="assets/img/logo_black.png" />
        </IonButton>
      </IonTitle>
      <IonTitle
        style={{ position: "relative", height: "52px" }}
        className="ion-text-center"
      ></IonTitle>
      <IonButtons slot="end">
        {cartCtx.cartItems != null && cartCtx.cartItems.length > 0 && (
          <React.Fragment>
            <IonButton
              routerLink={"/shop/"+props.shop_name+"/cart"}
              routerDirection="forward"
              mode="ios"
              fill="clear"
              color="dark"
              className="hidden-lg-up"
            >
              <IonIcon size="large" icon={basketOutline} />
            </IonButton>
            <IonBadge
              className="hidden-lg-up cart-badge-mobile"
              slot="end"
              color="primary"
            >
              {cartCtx.cartItems.length}
            </IonBadge>
          </React.Fragment>
        )}
        <IonButton
          className="hidden-md-down"
          routerLink={"/shop/"+props.shop_name+"/cart"}
          routerDirection={"forward"}
          mode="ios"
          color="dark"
          fill="clear"
        >
          <IonIcon icon={basketOutline} />
          {cartCtx.cartItems != null && cartCtx.cartItems.length > 0 && (
            <IonBadge
              className="hidden-md-down cart-badge-desktop"
              slot="end"
              color="primary"
            >
              {cartCtx.cartItems.length}
            </IonBadge>
          )}
        </IonButton>
      </IonButtons>
    </IonToolbar>
  );
};

export default CustomShopToolbar;
